/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import '../styles/CustomModal.scss';

function getModalStyle() {
  return {
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    width: '575px',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 40px)',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    outline: 'none',
    padding: '12px 32px'
  },
}));

export default function CustomModal() {
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(<div></div>);
  
  const classes = useStyles();
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (contentType) => {
    setOpen(true);
    contentType === 'tr' ? setContent(trBody) : (contentType === 'utt' ? setContent(uttBody) : setContent(utdBody));
  };

  // const CURRENT_YEAR = new Date().getFullYear() - 1;

  const trBody = (
    <div style={modalStyle} className={classes.paper}>
      <div className="close-modal"><CloseIcon onClick={handleClose} /></div>
      
      <h2 id="custom-modal-title">Technical Details</h2>
      <div id="custom-modal-description">
        <p>
          For each station, an array of partial duration series (PDS) was generated such that the shortest PDS 
          included data from 1950-1990 and the longest was based on data from 1950 through the most recent complete 
          year. Each PDS contained <i>n</i> values where <i>n</i> was defined as the total number of non-missing precipitation 
          values divided by the total number of days. For each PDS in the array, rainfall amounts corresponding 
          to recurrence probabilities of 50%, 20%, 10%, 4%, 2% and 1% (i.e. 2-, 5-, 10- 25-, 50- and 100-year storms) 
          were computed by simulating the methodology used in NOAA Atlas 14 (Bonnin et al., 2006; Perica et al., 2019).
        </p>
        
        <p>
          A maximum of 20 neighboring stations was identified and formed a region around each long-term station. 
          Neighboring stations were required to have at least 20 year of data in the 1950-present period. 
          Sample lmoments were obtained for each regional station using the python lmoments package 
          (<a href="https://pypi.org/project/lmoments/">https://pypi.org/project/lmoments/</a>) samlmu routine 
          and a weighted average of the higher order moments computed based on the length of each station’s PDS. 
          These weighted averages along with the base station’s location parameter were then used to obtain GEV 
          parameters and quantiles using the pelgev routine from the lmoments package. Other theoretical extreme 
          value distributions exist, but the GEV has been used extensively in prior extreme rainfall analyses 
          (e.g. Papalexiou and Koutsoyiannis, 2013). Given the L-moments estimates for the GEV parameters, the 
          lmoments library method was used to obtain the specified quantiles of the GEV distribution.
        </p>
        
        <p>
          Although this did not exactly replicate the Atlas 14 methodology, the differences between the resulting 
          annual recurrence interval (ARI) rainfall amounts and those given by Atlas 14 were generally small with 
          most values generally falling within the published Atlas 14 confidence intervals (Fig. 1). For the daily 
          return periods, approximately 40% of the stations’ 2-yr ARI amounts were within ± 2.5% of the 
          corresponding Atlas 14 value, with over 75% within ±5% of the published value. For the 5- and 10-yr ARI,
          values tended to be lower than the Atlas 14 values, but for longer ARI most of the differences became 
          positive. For the 50-yr ARI, the median difference between the two computation methods was +2.5%, while 
          for the 100-yr storm, approximately half of the stations’ ARI amounts were over 7.5% higher than the 
          published Atlas 14 values.
        </p>
        
        <img className='figure1' src={process.env.PUBLIC_URL + '/figures/figure1.jpg'}></img>
        
        <p className='figCaption'>
          Figure 1. Differences between 1-day Atlas 14 and project-computed recurrence interval rainfall amounts. 
          The boxplots show differences for each recurrence interval across all stations in the study domain. 
          The horizontal dotted lines denoting a ± 5% difference between the values.
        </p>
        
        <p>
          Differences in the lengths of the period of record used in Atlas 14, compared to the fixed 1950 starting 
          point record used in this study contribute to the overestimation of 100-yr ARI amounts compared to 2-yr 
          values. For example, a &gt;20% difference occurred at Wilmington Porter Reservoir in Delaware for which the
          Atlas 14 rainfall record begins in 1933. The PDS values that occurred prior to 1950 are systematically 
          smaller, with only 2 of the potential 17 PDS years (12%) recording rainfall &gt;9.27cm (the 1-day 5-year storm)
          whereas in the period from 1950-2000, 17 of the 51 years (33%) record rainfall exceeding this value. Thus, the 
          prevalence of positive differences reflects the tendency for extreme rainfall to increase with time 
          (e.g. DeGaetano, 2009) and also the potential for the longer PDS length in Atlas 14 to temper the extremes. 
          Since analyses used in this site are based on differences in recurrence interval rainfall amounts between the 
          longer (1950-present) and shorter (1950-1990) PDS periods, it was important to adapt a consistent reference 
          period (and methodology) through time.
        </p>
        <div className="modal-footnote"><i></i></div>
      </div>
    </div>
  );

  const utdBody = (
    <div style={modalStyle} className={classes.paper}>
      <div className="close-modal"><CloseIcon onClick={handleClose} /></div>
     
      <h2 id="custom-modal-title">About the Data</h2>
      <div id="custom-modal-description">
        <p>Data are from daily Cooperative Observing Network stations within the 12-state area served by the Northeast Regional Climate Center.  To be included a station must be 1) be available in NOAA Atlas 14 Volume 2 or Volume 10 (Bonnin et al., 2006; Perica et al., 2019) 2) have a data record that extends from at least 1950 through the current year and 3) have less than 5% of daily precipitation missing.  A larger set of regional stations having at least 20 years of record after 1980 is also used to compute return interval rainfall amounts at the longer-term base stations.  For all sites, daily rainfall observations and data quality flags were extracted from the Applied Climate Information System (ACIS) and reflect the values in the Global Historical Climatology Network Daily (GHCN-D, Menne et al., 2012).</p>
        <p>Partial duration rainfall series (PDS) were developed for 1-, 2-, 3-, 4-, 7-, 10-, and 20-day precipitation accumulations.  For each base station, an array of PDS was generated such that the shortest PDS included data from 1950-1990 and the longest was based on data from 1950 through the most recent year with complete data.  The use of a standard time period allows comparison among stations.</p>
        <p>Using the array of PDS at each station, rainfall amounts corresponding to recurrence probabilities of 50%, 20%, 10%, 4%, 2% and 1% (i.e. 2-, 5-, 10- 25-, 50- and 100-year storms) were computed by simulating the methodology used in NOAA Atlas 14 (Bonnin et al., 2006; Perica et al., 2019).  A maximum of 20 neighboring stations, identified from the previous set of regional stations, formed a region around each base station.  Sample lmoments were obtained for each regional station using the lmoments library samlmu routine and a weighted average of the higher order moments computed based on the length of each station’s PDS. The python lmoments package (<a href='https://pypi.org/project/lmoments/'>https://pypi.org/project/lmoments/</a>) was used to fit separate generalized extreme value (GEV) distribution to each station’s set of PDS using the methods of Hosking (1990).  Given the L-moments estimates for the GEV parameters, the lmoments library method was used to obtain the specified quantiles of the GEV distribution.</p>
        <p>Given the recurrence interval rainfall amounts for each duration, a proportional change was computed such that</p>
        <div id="equation">&Delta;P<sub>end</sub>=P(<i>d</i>,<i>r</i>)<sub>end</sub>/P(<i>d</i>,<i>r</i>)<sub>reference</sub></div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;where P is the precipitation amount corresponding to duration (<i>d</i>) and recurrence interval (<i>r</i>).  The subscripts <i>end</i> and <i>reference</i> represent the ending years of the two PDS being compared.   Proportional changes are available for four reference years (1900, 2000, 2010, and 2020) as well as the Atlas 14 value. When the end value is 2020, &Delta;P2020 compares the 1950-2020 PDS to that corresponding to the 1950-2000 PDS when 2000 is selected as the reference year.</p>
      </div>
    </div>
  );
  
  const uttBody = (
    <div style={modalStyle} className={classes.paper}>
      <div className="close-modal"><CloseIcon onClick={handleClose} /></div>

      <h2 id="custom-modal-title">Using the Tool</h2>
      <div id="custom-modal-description">
        <div className="list-title">The station map provides a:</div>
        <ol>
          <li>Regional overview of changes in extreme rainfall return period amounts between the most recent year (YYYY) and the selected base year.</li>
          <li>Method to select a specific station by clicking its marker to see a graph of annual changes in extreme rainfall return period amounts since 1950.  The graph as shows the magnitude of the new partial duration series rainfall amount that was added in a specific year.</li>
        </ol>

        <p>From the map, users can select different reference years (1990, 2000, 2010, 2020, or the Atlas 14 value), rainfall durations, and recurrence intervals. The color of each station marker depicts the value of one of three data options:</p>
        <ol>
          <li>The percent of the current year’s (YYYY) recurrence interval precipitation amount (P) relative to that of the selected reference year (P<sub>YYYY</sub>/P<sub>reference</sub>) x 100%. Values greater than 100% indicate an increase in extreme rainfall since the reference year.</li>
          <li>The recurrence interval rainfall amount (P<sub>YYYY</sub>) based on data from 1950-YYYY.</li>
          <li>The recurrence interval of the P<sub>reference</sub>  precipitation amount based on the full P<sub>YYYY</sub> data record.  Green makers indicate a decrease in the recurrence interval of the base year event.  This mean that an event that might have occurred once in 100 years (if the recurrence interval is set to 100-yr) based on the data series that ended in the reference year now occurs more frequently.  This is an indication that extreme rainfall has increased at the station.</li>
        </ol>
        
        <div className="list-title">Clicking on a station marker will open a time series graph.</div>
        <p>The graph can be saved in a specified format by clicking on the 3-line icon in the upper right.  To return to the map view, close the graph by clicking on the X in the upper right.</p>
        <p>From the graph, users can select different reference years (1990, 2000, 2010, 2020, or the Atlas 14 value), rainfall durations, and recurrence intervals.  These selections change either the blue plotted line or the gray reference year values.</p>
        <p>In addition users can select from 4 different data plots:</p>
        <ol>
          <li>The precipitation amount (in) corresponding to the selected duration and recurrence interval. This graph shows the amount based on PDS ending in the year indicated on the x-axis.  The reference year’s recurrence interval precipitation amount and the 95% (90% for Atlas 14) confidence interval about this value are shown in gray.</li>
          <li>The value from option 1, but plotted as a percentage of the reference year value (P<sub>year</sub>/P<sub>reference</sub>)x 100%</li>
          <li>The recurrence interval of the P<sub>reference</sub> precipitation amount based on the data record from 1950 to the year indicated on the x-axis. The gray line is the recurrence interval based on the 1950-reference year data record and thus will match the selected recurrence interval.  The gray area is the 95% or 90% (for Atlas 14) confidence interval interval for of the reference year recurrence interval.</li>
          <li>The value from option 3, but plotted as a percentage of the reference year value (recurrence<sub>1950-year</sub>/recurrence<sub>1950-reference year</sub>)x 100%.</li>
        </ol>
      </div>
    </div>
  );

  return (
    <div id="link-container">
      <Button className="link" onClick={() => handleOpen('utt')}>Using the Tool</Button>
      <Button className="link" onClick={() => handleOpen('utd')}>About the Data</Button>
      <Button className="link" onClick={() => handleOpen('tr')}>Technical Details</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
      >
        {content}
          
      </Modal>
    </div>
  );
}