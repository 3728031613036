import React from 'react';
import {
  Typography,
  LinearProgress,
} from '@material-ui/core';


const modalStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.5)',
  backdropFilter: 'blur(10px)',
  borderTop: '1px solid rgb(110,110,110)',
  borderBottom: '1px solid rgb(110,110,110)',
  boxSizing: 'border-box'
};

const progressStyle = {
  flexBasis: '100%',
  textAlign: 'center'
};


export default function Progress() { 
  return (
    <div id="modal" style={modalStyle}>
      <div id="progress-cont" style={progressStyle}>
        <Typography>Loading resources ...</Typography>
        <LinearProgress variant="indeterminate" />
      </div>
    </div>
  );
}