import React, { useContext, useMemo } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { OptionsContext, ChartContext } from './Contexts';

import '../styles/Nav.scss';

const chartTypes = {
  amount: 'Return Period Amount',
  interval: 'Recurrence Interval',
};
const dataYear = new Date().getFullYear() - 1;
const colorCodeOptions = {
  rdiff: [
    `Percent of ${dataYear} Return Period Amount Relative to `,
    ' Amount',
  ],
  amount: [`${dataYear} Return Period Amount`],
  interval: ['Recurrence of ', ` Storm in ${dataYear}`],
};
const durations = ['1', '2', '3', '4', '7', '10', '20'];
const recurrences = ['2', '5', '10', '25', '50', '100'];

export default function Nav() {
  const { options, setOptions } = useContext(OptionsContext);
  const { chart } = useContext(ChartContext);

  const memoizedChartTypeItems = useMemo(() => {
    let year =
      options['baseYear'] === 'atlas_14'
        ? 'Atlas 14'
        : String(1990 + parseInt(options['baseYear']));
    let items = [];

    Object.keys(chartTypes).forEach((chartGroup) => {
      items.push(
        <MenuItem key={chartGroup + 'A'} value={chartGroup + 'A'}>
          {chartTypes[chartGroup]}
        </MenuItem>
      );
      items.push(
        <MenuItem key={chartGroup + 'R'} value={chartGroup + 'R'}>
          % of {year} {chartTypes[chartGroup]}
        </MenuItem>
      );
    });

    return items;
  }, [options]);

  const memoizedColorCodeItems = useMemo(() => {
    let year =
      options['baseYear'] === 'atlas_14'
        ? 'Atlas 14'
        : String(1990 + parseInt(options['baseYear']));
    return Object.keys(colorCodeOptions).map((code) => {
      return (
        <MenuItem key={code} value={code}>
          {colorCodeOptions[code].join(year)}
        </MenuItem>
      );
    });
  }, [options]);

  const memoizedDurationItems = useMemo(() => {
    return durations.map((duration) => {
      return (
        <MenuItem key={duration} value={duration}>
          {duration} day
        </MenuItem>
      );
    });
  }, [durations]);

  const memoizedBaseYearItems = useMemo(() => {
    let cyis = [];

    for (var i = 1990; i < new Date().getFullYear(); i += 10) {
      let strI = i.toString();
      cyis.push(
        <MenuItem key={strI} value={(i - 1990).toString()}>
          {strI}
        </MenuItem>
      );
    }
    cyis.push(
      <MenuItem key='atlas_14' value='atlas_14'>
        Atlas 14
      </MenuItem>
    );

    return cyis;
  }, [options]);

  const memoizedrecurrenceItems = useMemo(() => {
    return recurrences.map((recurrence) => {
      return (
        <MenuItem key={recurrence} value={recurrence}>
          {recurrence}yr ({(100 / parseInt(recurrence)).toString()}%)
        </MenuItem>
      );
    });
  }, [recurrences]);

  const handleSelection = (event, key) => {
    setOptions({ ...options, [key]: event.target.value });
  };

  return (
    <div id='nav' className='card'>
      <Typography component='h1' variant='h5' id='title'>
        Options
      </Typography>

      {chart ? (
        <FormControl>
          <InputLabel>Plotted Value</InputLabel>
          <Select
            value={options['chartType']}
            onChange={(event) => handleSelection(event, 'chartType')}
          >
            {memoizedChartTypeItems}
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <InputLabel>Color Code</InputLabel>
          <Select
            value={options['colorCode']}
            onChange={(event) => handleSelection(event, 'colorCode')}
          >
            {memoizedColorCodeItems}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <InputLabel>Reference Year</InputLabel>
        <Select
          value={options['baseYear']}
          onChange={(event) => handleSelection(event, 'baseYear')}
        >
          {memoizedBaseYearItems}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel>Duration</InputLabel>
        <Select
          value={options['duration']}
          onChange={(event) => handleSelection(event, 'duration')}
        >
          {memoizedDurationItems}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel>Recurrence</InputLabel>
        <Select
          value={options['recurrence']}
          onChange={(event) => handleSelection(event, 'recurrence')}
        >
          {memoizedrecurrenceItems}
        </Select>
      </FormControl>
    </div>
  );
}
