import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';

import '../styles/Markers.scss';
import {
  SelectedStationContext,
  ChartContext
} from './Contexts';


export default function Markers({stations, stnAmounts, meta, options, colorData, onMarkerMouseEnter, onMarkerMouseLeave}) {
  const { setSelectedStation } = useContext(SelectedStationContext);
  const { setChart } = useContext(ChartContext);

  const [markerItems, setMarkerItems] = useState([]);

  const handleClick = (event, id) => {
    let currMI = document.getElementsByClassName('marker current');
    if (currMI.length > 0) {
      currMI[0].className = 'marker';
    }
    
    let newCurrMI;
    if (event.target.className === 'marker') {
      newCurrMI = event.target;
    } else {
      newCurrMI = event.target.parentNode;
    }
    newCurrMI.className = 'marker current';
    
    setSelectedStation(id);
    setChart(true);
  };

  useEffect(() => {
    let numRangePoints = colorData['rangePoints'].map(point => parseFloat(point));
    let MIs = Object.keys(stations).map((stnID) => {
      let stationObj;
      let value;

      if (options['colorCode'] === 'amount') {
        stationObj = stations[stnID];
        value = stnAmounts[stnID];
      } else {
        stationObj = stations[stnID];
        value = stationObj[options['colorCode']];
      }
      
      let stationMeta = meta[stnID];
      let color = 'rgb(0,0,0)';
      
      if (options['colorCode'] === 'interval') {
        for (let i = 0; i < numRangePoints.length; i++) {
          if (value < numRangePoints[i]) {
            color = colorData['colors'][i];
            break;
          }
        }
      } else {
        for (let i = 0; i < numRangePoints.length; i++) {
          if (value > numRangePoints[i]) {
            color = colorData['colors'][i];
            break;
          }
        }
      }

      if (color === 'rgb(0,0,0)') {
        color = colorData['colors'][colorData['colors'].length - 1];
      }

      if (options['colorCode'] !== 'amount' && options['baseYear'] === String(new Date().getFullYear() - 1991)) {
        color = 'rgb(255,255,255)';
      }

      return (
        <Marker
          key={stnID}
          latitude={stationMeta['lat']}
          longitude={stationMeta['lon']}
        >
          <div
            className='marker'
            onMouseEnter={() => onMarkerMouseEnter(stnID, stationMeta, stationObj, stnAmounts[stnID])}
            onMouseLeave={onMarkerMouseLeave}
            onClick={(event) => handleClick(event, stnID)}
          >
            <div className="marker-dot" style={{boxShadow: `0px 0px 0px 8px ${color}`}}></div>
          </div>
        </Marker>
      );
    });

    setMarkerItems(MIs);
  }, [options, colorData, stations]);

  return <>{markerItems}</>;
}

Markers.propTypes = {
  stations: PropTypes.object,
  stnAmounts: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.object.isRequired,
  colorData: PropTypes.object.isRequired,
  onMarkerMouseEnter: PropTypes.func.isRequired,
  onMarkerMouseLeave: PropTypes.func.isRequired
};