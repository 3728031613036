function getIncrementedNumber(beginNum, changeAmount, currIncrement, totalIncrements) {
  return beginNum + (changeAmount * (currIncrement / (totalIncrements - 1)));
}

export function generateColorGradient(colorArr, amountRequested) {
  let colors = [];
  let numColors = colorArr.length;
  let numDivisions;

  numDivisions = Math.ceil((amountRequested - numColors) / (numColors - 1) + (numColors - 1));

  for (let i = 0; i < numColors - 1; i++) {
    let c1 = colorArr[i];
    let c2 = colorArr[i + 1];

    for (let j = 0; j < numDivisions; j++) {
      if (i > 0 && j === 0) {
        continue;
      }

      let h = getIncrementedNumber(c1.h, c2.h - c1.h, j, numDivisions);
      let s = getIncrementedNumber(c1.s, c2.s - c1.s, j, numDivisions);
      let l = getIncrementedNumber(c1.l, c2.l - c1.l, j, numDivisions);
      colors.push(`hsl(${h},${s}%,${l}%)`);
    }
  }
  return colors;
}