import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Highcharts from 'highcharts';

import { ChartContext } from './Contexts';

import { useCurrentHeight, useCurrentWidth } from '../hooks/WindowSize';

import '../styles/Chart.scss';

import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
import highchartsMore from 'highcharts/highcharts-more';

HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);
highchartsMore(Highcharts);



export default function Chart({chartData, options, meta}) {
  const { setChart } = useContext(ChartContext);
  
  const [windowSize, setWindowSize] = useState({height: true, width: true});
  const [chartOptions, setChartOptions] = useState({});

  let width = useCurrentWidth();
  let height = useCurrentHeight();


  useEffect(() => {
    setWindowSize({height: (height > 650), width: (width > 1000)});
  }, [height, width]);

  useEffect(() => {
    if (chartData) {
      let dataType = options['chartType'];
      let byText = options['baseYear'] === 'atlas_14' ? 'Atlas 14' : String(1990 + parseInt(options['baseYear']));
      
      let fileType, yAxisTitle, chartTitle, units, rpORri;

      if (dataType.includes('interval')) {
        rpORri = 'Recurrence Interval';
        units = ' years';
        yAxisTitle = 'Recurrence Interval (years)';
        fileType = 'RecurranceInterval';
        chartTitle = `Recurrence Interval of ${byText} ${options['duration']}-Day ${options['recurrence']}-Year Storm at ${meta['name']}, ${meta['state']}`;
      } else {
        rpORri = 'Return Period Amount';
        units = 'in';
        yAxisTitle = 'Return Period Precipitation (in)';
        fileType = 'ReturnPeriodAmount';
        chartTitle = `${options['duration']}-Day ${options['recurrence']}-Year Return Period Precipitation Amount at ${meta['name']}, ${meta['state']}`;
      }

      if (dataType.includes('R')) {
        yAxisTitle = 'Percent (%)';
        fileType += `-PercentOf${options['baseYear']}`;
        chartTitle = `Percent of ${byText} ` + chartTitle;
      }

      let timeseriesSeries = chartData['timeseries'].map((percentileArr) => {
        let data = percentileArr[0];
        let color = percentileArr[1];
        let name = '';

        if (color.includes('rgb(20')) {
          name = 'Max';
        } else if (color.includes('rgb(115')) {
          name = '&GreaterEqual;90th Percentile';
        } else if (color.includes('rgb(210')) {
          name = '&GreaterEqual;75th Percentile';
        } else if (color.includes('rgb(195')) {
          name = '&GreaterEqual;50th Percentile';
        } else if (color.includes('rgb(180')) {
          name = '&#60;50th Percentile';
        }

        return {
          name: name,
          type: 'column',
          data: data,
          color: color,
          pointPadding: 0,
          groupPadding: 0.07,
          yAxis: 1,
          zIndex: 2,
          grouping: false,
          legendIndex: 5,
          linkedTo: 'bars',
          showInLegend: true
        };
      });
      

      let currChartOptions = {
        chart: {
          height: document.getElementById('chart-container').offsetHeight,
          width: document.getElementById('chart-container').offsetWidth,
          borderRadius: '12px',
          styledMode: true
        },

        credits: {
          enabled: false
        },

        exporting: {
          buttons: {
            contextButton: {
              x: -18,
              y: -6
            }
          },
          filename: `${fileType}-${options['duration']}day-${options['recurrence']}yr`,
        },

        title: {
          text: chartTitle
        },

        xAxis: {
          title: {
            text: 'Partial Duration Series Ending Year'
          }
        },

        yAxis: [{
          title: {
            text: yAxisTitle,
          }
        },{
          opposite: true,
          gridLineWidth: 0,
          
          title: {
            text: 'Largest New PDS Value (in)',
          },
        }],

        series: [
          ...timeseriesSeries,
          {
            name: 'Bars: PDS Value',
            type: options['chartType'].includes('R') ? 'bar' : 'scatter',
            data: [],
            legendIndex: 4,
            zIndex: 0,
            id: 'bars',
            marker: {
              enabled: false
            },
            lineWidth: 0,
            color: 'none'
          },
          {
            name: rpORri,
            color: 'rgb(69, 140, 255)',
            type: 'line',
            data: chartData['line'],
            marker: {
              symbol: 'circle',
              lineColor: 'white',
              lineWidth: 1
            },
            legendIndex: 0,
            zIndex: 3
          }
        ],
        tooltip: {
          shared: true,
          formatter: function() {
            let text;

            if (this.points.length >= 3) {
              try {
                text =  `<b>${this.points[1].x}</b> ${this.points[1].series.name}: <b>${this.points[1].y}</b>${units}` + '<br/>' +
                        `${this.points[2].series.name}: <b>${this.points[2].y}</b>${units}` + '<br/>' +
                        `${this.points[3].series.name}: <b>${this.points[3].point.low}</b>-<b>${this.points[3].point.high}</b>${units}` + '<br/>' +
                        `Highest PDS Value Added in <b>${this.points[0].x}</b>: <b>${this.points[0].y}</b>in`;
              } catch {
                text =  `<b>${this.points[0].x}</b> ${this.points[0].series.name}: <b>${this.points[0].y}</b>${units}` + '<br/>' +
                        `${this.points[1].series.name}: <b>${this.points[1].y}</b>${units}` + '<br/>' +
                        `${this.points[2].series.name}: <b>${this.points[2].point.low}</b>-<b>${this.points[2].point.high}</b>${units}`;
              }
            } else {
              try {
                text =  `Percent of <b>${this.points[1].x}</b> ${this.points[1].series.name}: <b>${this.points[1].y}</b>%` + '<br/>' +
                        `Highest PDS Value Added in <b>${this.points[0].x}</b>: <b>${this.points[0].y}</b>in`;
              } catch {
                text =  `<b>${this.points[0].x}</b> ${this.points[0].series.name}: <b>${this.points[0].y}</b>%`;
              }
            }
            

            return text;
          }
        },
        legend: {
          itemMarginTop: 4,
          itemMarginBottom: 4,
          layout: 'vertical',
          align : 'right',
          verticalAlign: 'middle',
          itemStyle: {
            width: '180px',
            textOverflow: 'wrap',
            fontSize: '11px'
          }
        }
      };

      if (options['chartType'].includes('A')) {
        currChartOptions['series'].push({
          name: byText + ' ' + rpORri,
          type: 'line',
          color: 'rgba(58, 58, 58, 0.6)',
          data: chartData['compLine'],
          marker: {
            enabled: false
          },
          legendIndex: 1,
          zIndex: 0
        });

        currChartOptions['series'].push({
          name: `${byText} ${rpORri} ${byText === 'Atlas 14' ? '90%' : '95%'} Confidence Interval`,
          type: 'arearange',
          fillColor: 'rgba(160, 160, 160, 0.6)',
          color: 'rgba(58, 58, 58, 0.6)',
          data: chartData['ciRange'],
          marker: {
            enabled: false
          },
          legendIndex: 2,
          zIndex: 1
        });
      }

      setChartOptions(currChartOptions);
    }
  }, [chartData, options, windowSize]);


  const handleClose = () => {
    setChart(false);
  };


  return (
    <div id="chart-container" className="card">
      <CloseIcon className="close-btn" onClick={handleClose}/>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}

Chart.propTypes = {
  chartData: PropTypes.object,
  options: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};