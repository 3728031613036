import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import ReactMapGL, { NavigationControl, Popup } from 'react-map-gl';

import '../styles/Map.scss';

import calcZoom from '../hooks/CalculateZoom';

import Markers from './Markers';

// Loading error fix
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass =
  // eslint-disable-next-line no-undef
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const dataYear = new Date().getFullYear() - 1;

export default function Map({
  stations,
  stnAmounts,
  meta,
  options,
  colorData,
}) {
  const [popup, setPopup] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 43.12,
    longitude: -73.81,
    zoom: 5.3,
    minZoom: 3,
  });

  useEffect(() => {
    const map = document.getElementById('map-cont');
    const zoom = calcZoom(map.offsetHeight, map.offsetWidth, viewport.zoom);
    setViewport({ ...viewport, zoom: zoom });
  }, []);

  const handleMarkerMouseEnter = (id, meta, stnObj, value) => {
    setPopup({
      ...meta,
      id: id,
      value: value,
      byValue: stnObj['amount'],
      stormrecurrenceValue: stnObj['interval'],
      percent: stnObj['rdiff'],
      year:
        options['baseYear'] === 'atlas_14'
          ? 'Atlas 14'
          : String(1990 + parseInt(options['baseYear'])),
    });
  };

  const handleMarkerMouseLeave = () => {
    setPopup(null);
  };

  const handlePanning = (view) => {
    let nextView = view;

    if (nextView.latitude > 45.6 || nextView.latitude < 37) {
      nextView.latitude = viewport.latitude;
    }

    if (nextView.longitude > -69 || nextView.longitude < -82) {
      nextView.longitude = viewport.longitude;
    }

    setViewport(nextView);
  };

  return (
    <div id='map-cont'>
      <ReactMapGL
        {...viewport}
        width='100%'
        height='100%'
        onViewportChange={(nextViewport) => handlePanning(nextViewport)}
        // mapboxApiAccessToken="pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2t2NzFqOWUxN2l3bTJvb2Z0eDVvbHNhdiJ9.q53VgDXZ2ZLtEQO57WgtNw"
        mapboxApiAccessToken='pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2txYjNjMHYxMGF4NTJ1cWhibHNub3BrdiJ9.1T_U5frbnHaHonvFpHenxQ'
        mapStyle='mapbox://styles/precipadmin/ckqb3lk2d349917n2z4qnn4pu'
      >
        {stations && (
          <Markers
            stations={stations}
            stnAmounts={stnAmounts}
            meta={meta}
            options={options}
            colorData={colorData}
            onMarkerMouseEnter={handleMarkerMouseEnter}
            onMarkerMouseLeave={handleMarkerMouseLeave}
          />
        )}

        <NavigationControl className='map-nav' showCompass={false} />

        {popup && (
          <Popup
            tipSize={5}
            anchor='top'
            longitude={popup.lon}
            latitude={popup.lat}
            closeOnClick={false}
            closeButton={false}
            onClose={() => setPopup(null)}
          >
            <div className='popup-text'>
              <div className='popup-title'>
                #{popup.id.substring(5)}: {popup.name}, {popup.state}
              </div>
              <div className='popup-footnote'>
                ({popup.lat}&deg;N,{popup.lon}&deg;W)
              </div>
              <hr />
              {/* <div className='popup-options'>
                <div>{options['duration']} day</div>
                <div>{options['recurrence']} yr</div>
              </div> */}
              <div className='popup-splitline with-spacers'>
                <div>Return Period Amount Through {dataYear}:</div>
                <div>{popup.value}&rdquo;</div>
              </div>
              <div className='popup-title'>
                Based on Data Through {dataYear}
              </div>
              <div className='popup-splitline'>
                <div>
                  Recurrence of {popup.year} Storm ({popup['byValue']}&quot;):
                </div>
                <div>{popup['stormrecurrenceValue'].toFixed(0)}yr</div>
              </div>
              <div className='popup-splitline'>
                <div>
                  Percent of {popup.year} Rainfall ({popup['byValue']}&quot;):
                </div>
                <div>
                  {popup['percent'] > 0 ? '+' : ''}
                  {popup['percent'].toFixed(0)}%
                </div>
              </div>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    </div>
  );
}

Map.propTypes = {
  stations: PropTypes.object,
  stnAmounts: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.object.isRequired,
  colorData: PropTypes.object.isRequired,
};
