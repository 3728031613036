/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import pako from 'pako';
import ReactGA from 'react-ga4';

import '../styles/App.scss';
import {
  OptionsContext,
  SelectedStationContext,
  ChartContext,
} from './Contexts';

import Header from './Header';
import Map from './Map';
import Sidebar from './Sidebar';
import Nav from './Nav';
import LoadingOverlay from './LoadingOverlay';
import BinnedLegend from './BinnedLegend';
import Chart from './Chart';

import { generateColorGradient } from '../hooks/CalculateColor';

function getMinMax(data, colorCode) {
  let min = 999;
  let max = -999;

  Object.keys(data).forEach((stn_id) => {
    let value = colorCode === 'amount' ? data[stn_id] : data[stn_id][colorCode];
    if (value > max) {
      max = value;
    }

    if (value < min) {
      min = value;
    }
  });

  return [min, max];
}

function calcRangePoints(min, max, numBins, precision) {
  let rangePoints = [];
  let rangeAmt = (max - min) / (numBins - 2);
  for (let i = 0; i <= numBins - 3; i++) {
    let newPoint = (min + i * rangeAmt).toFixed(precision);

    if (!rangePoints.includes(newPoint)) {
      rangePoints.push(newPoint);
    }

    if (i === numBins - 3 && !rangePoints.includes(max.toFixed(precision))) {
      rangePoints.push(max.toFixed(precision));
    }
  }

  return rangePoints;
}

function fetchData(fileName, dir) {
  try {
    const data = fetch(
      process.env.PUBLIC_URL + '/data/' + dir + '/' + fileName + '.json.gz',
      {
        mode: 'cors',
      }
    )
      .then((res) => res.arrayBuffer())
      .then((arrBuff) => pako.inflate(arrBuff))
      .then((buff) => new TextDecoder().decode(buff))
      .then((str) => JSON.parse(str));

    return data;
  } catch (err) {
    console.error('Unable to fetch and decompress data...', err);
    return {};
  }
}

function calcPercent(n1, n2) {
  return +((n1 / n2) * 100).toFixed(2);
}

export default function App() {
  const [mapData, setMapData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [stnAmounts, setStnAmounts] = useState(null);
  const [currMapStations, setCurrMapStations] = useState(null);
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedStationData, setSelectedStationData] = useState(null);
  const [currChartData, setCurrChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [colorData, setColorData] = useState({ colors: [], rangePoints: [] });
  const [chart, setChart] = useState(false);
  const [options, setOptions] = useState({
    colorCode: 'rdiff',
    baseYear: '10',
    duration: '1',
    recurrence: '100',
    chartType: 'amountA',
  });

  // Send analytics data to Google
  useEffect(() => {
    ReactGA.initialize('G-QC91RNZWXH');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Fetch mapData and metaData on page load
  // Toggles overlay when loading
  useEffect(() => {
    const getMapData = async () => {
      let mapd = await fetchData('map_data', 'map');
      let metad = await fetchData('meta_data', 'map');

      setMapData(mapd);
      setMetaData(metad);
      setLoading(false);
    };

    setLoading(true);
    getMapData();
  }, []);

  // Store present year's value for tooltip and store current base year's station data
  // Update when options or mapData change
  useEffect(() => {
    if (mapData) {
      setCurrMapStations(
        mapData[options['duration']][options['recurrence']][options['baseYear']]
      );
    }
  }, [options, mapData]);

  useEffect(() => {
    if (mapData) {
      setStnAmounts(
        mapData[options['duration']][options['recurrence']]['amount']
      );
    }
  }, [mapData, options]);

  // Calculates colorData
  // Updates when options or currMapStations change
  useEffect(() => {
    if (currMapStations && stnAmounts) {
      // Only use odd numbers for numBins, generateColorGradient() doesn't accept even numbers
      const numBins = 19;
      const color1 = {
        h: 30,
        s: 100,
        l: 18,
      };

      const color2 = {
        h: 60,
        s: 100,
        l: 63,
      };

      const color3 = {
        h: 120,
        s: 100,
        l: 13,
      };

      let [min, max] = getMinMax(
        options['colorCode'] === 'amount' ? stnAmounts : currMapStations,
        options['colorCode']
      );
      let precision = 2;

      let rangePoints;
      let colorGradient;
      if (options['colorCode'] === 'amount') {
        colorGradient = generateColorGradient(
          [color1, color2, color3],
          numBins
        );
        rangePoints = calcRangePoints(min, max, numBins, precision);
      } else {
        let midPoint;
        precision = 0;

        if (options['colorCode'] === 'interval') {
          midPoint = parseInt(options['recurrence']);
        } else if (options['colorCode'] === 'amount') {
          midPoint = 0;
        } else {
          midPoint = 100;
        }

        if (max > midPoint && min < midPoint) {
          let greatestDiff = Math.abs(Math.max(max - midPoint, midPoint - min));

          let upperHalf = calcRangePoints(
            midPoint,
            midPoint + greatestDiff,
            (numBins - 1) / 2,
            precision
          );
          let lowerHalf = calcRangePoints(
            midPoint - greatestDiff,
            midPoint,
            (numBins - 1) / 2,
            precision
          );

          rangePoints = lowerHalf.concat(upperHalf);
          let bins =
            rangePoints.length % 2 === 1
              ? rangePoints.length + 1
              : rangePoints.length + 2;

          colorGradient = generateColorGradient([color1, color2, color3], bins);
        } else if (min >= midPoint) {
          rangePoints = calcRangePoints(midPoint, max, numBins, precision);
          colorGradient = generateColorGradient(
            [color2, color3],
            rangePoints.length + 2
          );
        } else if (max <= midPoint) {
          rangePoints = calcRangePoints(min, midPoint, numBins, precision);
          let bins =
            rangePoints.length % 2 === 1
              ? rangePoints.length + 1
              : rangePoints.length + 2;

          colorGradient = generateColorGradient([color2, color3], bins);
        }
      }

      if (options['colorCode'] === 'interval') {
        colorGradient.reverse();
      } else {
        rangePoints.reverse();
        colorGradient.reverse();
      }

      setColorData({ colors: colorGradient, rangePoints: rangePoints });
    }
  }, [options, currMapStations, stnAmounts]);

  // Fetch currChartData after station is selected
  // Update when selectedStation changes
  useEffect(() => {
    const getChartData = async (stnID) => {
      let chartd = await fetchData(stnID, 'charts');

      setSelectedStationData(chartd);
      setLoading(false);
    };

    if (selectedStation) {
      setLoading(true);
      getChartData(selectedStation);
    }
  }, [selectedStation]);

  useEffect(() => {
    if (selectedStationData) {
      let recData =
        selectedStationData[options['duration']][options['recurrence']];
      let presentYearValue, baseYearVals;
      let lineData = [];
      let ciRange = [];
      let compLine = [];

      if (options['chartType'].includes('amount')) {
        Object.keys(recData).forEach((year) => {
          if (year !== 'atlas_14') {
            lineData.push(recData[year]['main']['rainfall']);
          }
        });

        if (options['chartType'].includes('R')) {
          presentYearValue = recData[options['baseYear']]['main']['rainfall'];
        } else {
          baseYearVals = recData[options['baseYear']]['main'];
        }
      } else {
        lineData = recData[options['baseYear']]['storm_recurrence']['data'];

        if (options['chartType'].includes('R')) {
          presentYearValue = lineData[options['baseYear']];
        } else {
          baseYearVals =
            recData[options['baseYear']]['storm_recurrence']['cis'];
        }
      }

      lineData = lineData.map((value, index) => {
        let year = index + 1990;
        if (options['chartType'].includes('R')) {
          return [year, calcPercent(value, presentYearValue)];
        } else {
          ciRange.push([
            year,
            baseYearVals['lower_ci'],
            baseYearVals['upper_ci'],
          ]);
          compLine.push([
            year,
            baseYearVals['rainfall'] || baseYearVals['interval'],
          ]);
          return [year, value];
        }
      });

      setCurrChartData({
        timeseries: selectedStationData[options['duration']]['timeseries'],
        line: lineData,
        compLine: compLine,
        ciRange: ciRange,
      });
    }
  }, [options, selectedStationData]);

  return (
    <OptionsContext.Provider value={{ options, setOptions }}>
      <SelectedStationContext.Provider
        value={{ selectedStation, setSelectedStation }}
      >
        <ChartContext.Provider value={{ chart, setChart }}>
          <div className='App'>
            <div id='moon'></div>
            <Header />
            <Map
              stations={currMapStations}
              stnAmounts={stnAmounts}
              meta={metaData}
              options={options}
              colorData={colorData}
            />
            <div id='overlay-grid'>
              <Nav />
              <BinnedLegend
                rangePoints={colorData['rangePoints']}
                colorArr={colorData['colors']}
                units={
                  options['colorCode'] === 'rdiff'
                    ? '%'
                    : options['colorCode'] === 'amount'
                    ? '"'
                    : 'yr'
                }
              />
              {chart && (
                <Chart
                  chartData={currChartData}
                  options={options}
                  meta={metaData[selectedStation]}
                />
              )}
            </div>
            <Sidebar />
            {loading && <LoadingOverlay />}
          </div>
        </ChartContext.Provider>
      </SelectedStationContext.Provider>
    </OptionsContext.Provider>
  );
}
