/* eslint-disable no-undef */
import React from 'react';

import CustomModal from './CustomModal';

import '../styles/Sidebar.scss';

export default function Sidebar() {
  return (
    <nav className="sidebar">
      <div id="sidebar-content">
        <section className='sidebar-top'>
          <CustomModal />
        </section>

        <section className='sidebar-bottom'>
          <div className="sidebar-text">
            <p>NRCC supports a three-tiered national climate services support program. The partners include: <a href="https://stateclimate.org/">State Climate Offices</a>, <a href="https://www.ncdc.noaa.gov/customer-support/partnerships/regional-climate-centers">Regional Climate Centers</a>, and <a href="https://www.ncei.noaa.gov/">the National Centers for Environmental Information</a>.</p>
            <p>Contact: <a href="mailto: nrcc@cornell.edu">NRCC.cornell.edu</a></p>
            <p>Copyright 2021 Northeast Regional Climate Center</p>
          </div>
          <div className="sidebar-logos-bottom">
            <a id="sidebar-bottom-acis" href="https://www.rcc-acis.org/">
              <div id="acis-cont"><img id="acis" src={process.env.PUBLIC_URL + '/logos/ACIS_NRCC.jpg'} alt="Organization logo for the Regional Climate Centers Applied Climate Information System" /></div>
            </a>
          </div>
        </section>
      </div>
    </nav>
  );
}