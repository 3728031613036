export default function calcZoom(height, width, currZoom) {
  let widthZoom = 24;
  let heightZoom = 24;

  // Currently, width does not need to restrict zoom
  // Use this portion if the need arises.

  // if (width < biggestWidthAllowed && width >= smallestWidthAllowed) {
  //   widthZoom = minimumNecessaryInitialZoom + (((width - smallestWidthAllowed) / diffBetweenBiggestAndSmallestAllowedWidths) * diffBetweenMinimumNecessaryInitialZoomAndLargestNecessaryInitialZoom);
  // }

  if (height >= 450) {
    heightZoom = 4.50 + (((height - 450) / 950) * 1.8);
  }

  return Math.min(currZoom, widthZoom, heightZoom);
}