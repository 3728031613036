import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../styles/BinnedLegend.scss';

export default function BinnedLegend({rangePoints, colorArr, units}) {
  const [legendItems, setLegendItems] = useState([]);
      
  useEffect(() => {
    let items = [];

    if (rangePoints.length === 1) {
      items.push(<div key='single'><div style={{backgroundColor:'rgb(255,255,255)'}}>{rangePoints[0]}{units}</div></div>);
    } else {
      for (let i = 0; i < rangePoints.length + 1; i++) {
        let text = '';
        
        if (i === 0 && parseFloat(rangePoints[0]) < parseFloat(rangePoints[1])) {
          text = `Below ${rangePoints[i]}${units}`;
        } else if (i === rangePoints.length && parseFloat(rangePoints[0]) < parseFloat(rangePoints[1])) {
          text = `${rangePoints[i - 1]}${units}+`;
        } else if (i === 0) {
          text = `${rangePoints[i]}${units}+`;
        } else if (i === rangePoints.length) {
          text = `Below ${rangePoints[i - 1]}${units}`;
        } else {
          let start = rangePoints[i - 1];
          let end = rangePoints[i];
          
          if (start === end) {
            text = `< ${start}${units} >`;
          } else {
            text = `${start}${units} - ${end}${units}`;
          }
        }
        
        items.push(<div key={i}><div style={{backgroundColor:colorArr[i]}}>{text}</div></div>);
      }
    }

    setLegendItems(items);
  }, [rangePoints, colorArr]);
     
  
  return (
    <div id="legend-container">
      <div id="color-container">
        {legendItems}
      </div>
    </div>
  );
}

BinnedLegend.propTypes = {
  rangePoints: PropTypes.array.isRequired,
  colorArr: PropTypes.array.isRequired,
  units: PropTypes.string.isRequired,
};