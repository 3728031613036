/* eslint-disable no-undef */
import React from 'react';

import '../styles/Header.scss';

export default function Header() {
  return (
    <header className="header">
      <a id="nrcc-logo-cont" href="http://www.nrcc.cornell.edu/">
        <div id="nrcc-logo"><img id='nrcc' src={process.env.PUBLIC_URL + '/logos/nrcc.svg'} alt="Organization logo for Northeast Regional Climate Center." /></div>
      </a>

      <div className="header-center">
        <div id="title-container">
          <div id="site-title">Recent Extreme Precipitation Changes in the Northeast U.S.</div>
        </div>
      </div>
    </header>
  );
}